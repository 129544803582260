//import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { LinksFunction } from "@remix-run/cloudflare";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";

import "@lutains/ui/style.css";
import "./index.scss";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Lutains, c'est le tout premier outil d'analyse de landing pages! Accède à des retours pertinents sur ta landing page en un clin d'oeil! Copywriting, design, accessibilité, tout y passera! "
        />
        <title>Lutains | Le décodeur de landing pages que tu vas adorer!</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content="Lutains | Le décodeur de landing pages que tu vas adorer!"
        />
        <meta
          property="og:description"
          content="Lutains, c'est le tout premier outil d'analyse de landing pages! Accède à des retours pertinents sur ta landing page en un clin d'oeil! Copywriting, design, accessibilité, tout y passera!"
        />
        <meta
          property="og:image"
          content="https://cdn.lutains.com/assets/sticker.png"
        />
        <meta property="og:url" content="https://www.lutains.com" />
        <link rel="stylesheet" href="https://use.typekit.net/hms0jym.css" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  //captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
